import React, { Fragment, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import imageUploader from "../../../utilities/imageUploader";
import axios from "../../../axios/axios";
import Swal from "sweetalert2";

const AddCircular = ({ isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState(null);
  const [btnActive, setBtnActive] = useState(false);
  const [file, setFile] = useState("");

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    const form = e.target;
    const title = form.title.value;
    const description = form.description.value;
    const circular_date = form.circular_date.value;
    const pdf_url = form.pdf_url.value;
    const image_url = await imageUploader(image);
    const postData = {
      title,
      description,
      image_url,
      circular_date,
      // pdf_url: file.name,
      pdf_url: pdf_url,
    };

    // console.log("postData", postData);

    try {
      await axios.post("/circulars", postData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <Fragment>
      <Button color="primary" onClick={toggle}>
        Add New Circular
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5>Circular Uploader</h5>
            </div>
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="title">
                        Title
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="description">
                        Description
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="description"
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label
                        className="text-capitalize"
                        htmlFor="circular_date"
                      >
                        Circular Date
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="circular_date"
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="pdf_url">
                        PDF URL
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="pdf_url"
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          id="fileInput"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                            setBtnActive(true);
                          }}
                          accept="image/*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddCircular;
