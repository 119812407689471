import React, { Fragment, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios/axios";
import videoUploader from "../../../utilities/videoUploader";

const AddVideoGallery = ({ isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const name = form.name.value;
    setLoading(true);
    const video_url = await videoUploader(form.video_url.files[0]);

    console.log(video_url);
    const postData = { name, video_url };

    try {
      await axios.post("/video-gallery", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle();
      setIsUpdate(!isUpdate);
      setLoading(false);
    } catch (error) {
      alert("Error submitting data.");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button color="primary" onClick={toggle}>
        Add New Video Gallery
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5>Video Gallery Uploader</h5>
            </div>
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="video_url">
                        Video URL
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="video_url"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!loading ? (
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                </div>
              ) : (
                <div class="spinner-border mx-3" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </form>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddVideoGallery;
