import { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from "../../axios/axios";

const PrivateRoute = () => {
  const [auth, setAuth] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // send jwt to API to see if it's valid
    let token = localStorage.getItem("access-token");

    // console.log(token);

    if (token) {
      axios
        .post("/user/verify")
        .then((res) => {
          if (
            res?.data?.body?.decoded?.user === "admin" ||
            res?.data?.body?.decoded?.user === "global_communications"
          ) {
            setAuth(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setAuth(false);
          localStorage.removeItem("token");
        })
        .then(() => setIsTokenValidated(true));
    } else {
      setIsTokenValidated(true); // in case there is no token
    }
  }, [navigate]);

  if (!isTokenValidated) return <p>loading</p>;
  console.log(auth);

  return auth ? (
    <Outlet />
  ) : (
    <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
  );
};

export default PrivateRoute;
