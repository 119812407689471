import { useEffect, useState } from "react";
import CKEditors from "react-ckeditor-component";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../axios/axios";
import imageUploader from "../../utilities/imageUploader";
import Breadcrumb from "../common/breadcrumb";

const Values = () => {
  const [data, setData] = useState({});
  const [modal, setModal] = useState();
  const [image, setImage] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [cntnt, setCntnt] = useState();
  const [btnActive, setBtnActive] = useState(false);

  const onChange = (evt) => {
    const newCntnt = evt.editor.getData();
    setCntnt(newCntnt);
    setBtnActive(true);
  };

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/shareds?type_name=values");
        console.log(response?.data?.body?.data?.[0]);
        setData(response?.data?.body?.data?.[0]);
        const image_urls = response?.data?.body?.data?.[0]?.image_urls;
        setImgUrl(image_urls);
        setCntnt(response?.data?.body?.data?.[0]?.contents);
      } catch (error) {
        console.error("Error", error);
      }
    };

    getData();
  }, [isUpdate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const title = form.title.value;
    const contents = cntnt;

    const image_url = imgUrl ? imgUrl : await imageUploader(image);
    const image_urls = `${image_url}`;

    console.log(image_url);
    console.log(image_urls);

    const postData = {
      title,
      contents,
      image_urls,
      type_name: "values",
    };
    console.log(postData);

    try {
      await axios.put("/shareds", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsUpdate(!isUpdate);
      toggle();
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <div>
      <Breadcrumb parent="Values" title="Values" />
      <Button className="mb-3" color="primary" onClick={toggle}>
        Update
      </Button>
      <div className="col-sm-12 col-xl-12">
        <div className="card height-equal">
          <div className="card-header">
            <h5 className="text-center">{data?.title}</h5>
          </div>
          <div className="card-body d-flex gap-3 justify-content-center">
            <div className="text-center" style={{ height: "200px" }}>
              <img
                style={{ height: "200px" }}
                src={data?.image_urls}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="card-footer">
            <div dangerouslySetInnerHTML={{ __html: data?.contents }}></div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-body"
          centered={true}
        >
          <ModalHeader toggle={toggle}>Values</ModalHeader>
          <ModalBody>
            <div className="card">
              <div className="card-header">
                <h5>Values</h5>
              </div>
              <form
                onSubmit={(e) => {
                  setBtnActive(false);
                  handleSubmit(e);
                }}
                className="form theme-form"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="title">
                          Title
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="title"
                          defaultValue={data?.title}
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="content">
                          Contents
                        </label>
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-body">
                                  <CKEditors
                                    activeclassName="p10"
                                    content={cntnt}
                                    events={{
                                      change: onChange,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Image
                        </label>
                        <div className="col-sm-9">
                          <div className="col-sm-9">
                            {imgUrl ? (
                              <div style={{ position: "relative" }}>
                                <img
                                  style={{
                                    width: "300px",
                                    display: "block",
                                    margin: "15px auto",
                                  }}
                                  src={imgUrl}
                                  alt=""
                                />
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    border: "none",
                                    borderRadius: "7px",
                                    position: "absolute",
                                    right: "0",
                                    top: "0",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setImgUrl(null)}
                                >
                                  <i
                                    style={{
                                      color: "white",
                                      padding: "7px 7px",
                                      fontSize: "15px",
                                    }}
                                    className="fa fa-times"
                                  ></i>
                                </span>
                              </div>
                            ) : (
                              <input
                                className="form-control"
                                type="file"
                                id="fileInput"
                                onChange={(e) => {
                                  setImage(e.target.files[0]);
                                  setBtnActive(true);
                                }}
                                accept="image/*"
                                required
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default Values;
