import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../axios/axios";
import imageUploader from "../../utilities/imageUploader";
import Breadcrumb from "../common/breadcrumb";

const Settings = () => {
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [modal, setModal] = useState();
  const [logo, setLogo] = useState(null);
  const [lgUrl, setLgUrl] = useState();
  const [btnActive, setBtnActive] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/settings");
        console.log(response?.data?.body?.data?.[0]);
        setData(response?.data?.body?.data?.[0]);
        setLgUrl(response?.data?.body?.data?.[0]?.logo_url);
      } catch (error) {
        console.error("Error", error);
      }
    };

    getData();
  }, [isUpdate]);

  const toggle2 = () => {
    setModal(!modal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const title = form.title.value;
    const slogan = form.slogan.value;
    const addresses = form.addresses.value;
    const social_links = form.social_links.value;
    const phones = form.phones.value;
    const emails = form.emails.value;

    const logo_url = lgUrl ? lgUrl : await imageUploader(logo);

    const postData = {
      title,
      slogan,
      addresses,
      social_links: social_links,
      phones,
      emails,
      logo_url,
    };

    console.log(postData);

    try {
      await axios.put(`/settings/${data?.id}`, postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsUpdate(!isUpdate);
      toggle2();
    } catch (error) {
      alert("Error submitting data.");
      setBtnActive(true);
    }
  };

  return (
    <div>
      <Breadcrumb parent="Settings" title="Settings" />
      <div className="mb-2">
        <Button color="primary" onClick={toggle2}>
          Update
        </Button>
      </div>
      <div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Logo </h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  {data?.logo_url && (
                    <img
                      style={{ height: "100%" }}
                      className="image-fluid"
                      src={data?.logo_url}
                      alt="logo"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Title</h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  <p style={{ fontSize: "2em" }}>{data?.title}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Slogan </h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  <p>{data?.slogan}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Addresses </h5>
                <div style={{ minHeight: "100px" }}>
                  <p>{data?.addresses}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Social Links </h5>
                <div style={{ minHeight: "100px" }}>
                  <p>{data?.social_links}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Phones</h5>
                <div style={{ height: "100px" }}>{data?.phones}</div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Emails</h5>
                <div style={{ height: "100px" }}>
                  <p>{data?.emails}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle2}
          className="modal-body"
          centered={true}
        >
          <ModalHeader toggle={toggle2}>Settings</ModalHeader>
          <ModalBody>
            <div className="card">
              <div className="card-header">
                <h5>Update Settings</h5>
              </div>
              <form
                onSubmit={(e) => {
                  setBtnActive(false);
                  handleSubmit(e);
                }}
                className="form theme-form"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="title">
                          Title
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="title"
                          defaultValue={data?.title}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="slogan">
                          Slogan
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="slogan"
                          defaultValue={data?.slogan}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="addresses">
                          Addresses
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="addresses"
                          defaultValue={data?.addresses}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="text-capitalize"
                          htmlFor="social_links"
                        >
                          Social Links
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="social_links"
                          defaultValue={data?.social_links}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="phones">
                          Phones
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="phones"
                          defaultValue={data?.phones}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="emails">
                          Emails
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="emails"
                          defaultValue={data?.emails}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Logo
                        </label>
                        <div className="col-sm-9">
                          {lgUrl ? (
                            <div style={{ position: "relative" }}>
                              <img
                                style={{
                                  width: "300px",
                                  display: "block",
                                  margin: "15px auto",
                                }}
                                src={lgUrl}
                                alt=""
                              />
                              <span
                                style={{
                                  backgroundColor: "red",
                                  border: "none",
                                  borderRadius: "7px",
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                  cursor: "pointer",
                                }}
                                onClick={() => setLgUrl(null)}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    padding: "7px 7px",
                                    fontSize: "15px",
                                  }}
                                  className="fa fa-times"
                                ></i>
                              </span>
                            </div>
                          ) : (
                            <input
                              className="form-control"
                              type="file"
                              id="fileInput"
                              onChange={(e) => {
                                setLogo(e.target.files[0]);
                                setBtnActive(true);
                              }}
                              accept="image/*"
                              required
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle2}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default Settings;
