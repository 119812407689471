import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddVideoGallery from "./AddVideoGallery/AddVideoGallery";
import VideoGallery from "./VideoGallery/VideoGallery";

const VideoGalleries = () => {
  const [videoGalleries, setVideoGalleries] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getVideoGalleries = async () => {
      try {
        const res = await axios.get("/video-gallery");
        console.log(res?.data?.body?.data);
        setVideoGalleries(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getVideoGalleries();
  }, [isUpdate]);

  return (
    <div>
      <h2>Video Galleries</h2>
      <div className="mb-3">
        <AddVideoGallery isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {videoGalleries.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Video</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {videoGalleries?.map((videoGallery, index) => (
              <VideoGallery
                key={videoGallery?.id}
                videoGallery={videoGallery}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default VideoGalleries;
