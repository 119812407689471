import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddSlider from "./AddSlider/AddSlider";
import Slider from "./Slider/Slider";

const Sliders = () => {
  const [sliders, setSliders] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getSliders = async () => {
      try {
        const res = await axios.get("/sliders");
        console.log(res?.data?.body?.data);
        setSliders(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getSliders();
  }, [isUpdate]);

  return (
    <div>
      <h2>Sliders</h2>
      <div className="mb-3">
        <AddSlider isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {sliders.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {sliders?.map((slider, index) => (
              <Slider
                key={slider?.id}
                slider={slider}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Sliders;
