import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import Newsletter from "./Newsletter/Newsletter";

const Newsletters = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getNewsletters = async () => {
      try {
        const res = await axios.get("/newsletters");
        console.log(res?.data?.body?.data);
        setNewsletters(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getNewsletters();
  }, [isUpdate]);

  return (
    <div>
      <h2>Newsletters</h2>
      {newsletters.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {newsletters?.map((newsletter, index) => (
              <Newsletter
                key={newsletter?.id}
                newsletter={newsletter}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Newsletters;
