import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddCircular from "./AddCircular/AddCircular";
import Circular from "./Circular/Circular";

const Circulars = () => {
  const [circulars, setCirculars] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getCirculars = async () => {
      try {
        const res = await axios.get("/circulars");
        console.log(res?.data?.body?.data);
        setCirculars(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getCirculars();
  }, [isUpdate]);

  return (
    <div>
      <h2>Circulars</h2>
      <div className="mb-3">
        <AddCircular isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {circulars.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Circular Date</th>
              <th scope="col">Image</th>
              <th scope="col">PDF</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {circulars?.map((circular, index) => (
              <Circular
                key={circular?.id}
                circular={circular}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Circulars;
