import Axios from "axios";
// console.log(localStorage.getItem("access-token"));

const axios = Axios.create({
  baseURL: "https://global-communications-bd-backend.vercel.app/",
  // baseURL: "http://localhost:5000/",
});


axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("access-token");

export default axios;
