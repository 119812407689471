import { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios/axios";
import imageUploader from "../../../utilities/imageUploader";

const UpdateImageGallery = ({ imageGallery, index, isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [cover, setCover] = useState(imageGallery?.cover_url);
  const [btnActive, setBtnActive] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const handleUpdate = async (id) => {
      const form = e.target;
      const album_name = form.album_name.value;

      const cover_url = await imageUploader(cover);


      const postData = { album_name, cover_url };

      try {
        await axios.put(`/image-gallery/${id}`, postData);
        Swal.fire({
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsUpdate(!isUpdate);
        toggle();
      } catch (error) {
        alert("Error submitting data.");
      }
    };

    handleUpdate(imageGallery.id);
  };

  return (
    <>
      <button
        style={{
          backgroundColor: "#515ef1",
          border: "none",
          borderRadius: "5px",
        }}
        onClick={toggle}
      >
        <i
          style={{
            color: "white",
            padding: "7px 3px",
            fontSize: "15px",
          }}
          className="fa fa-pencil"
        ></i>
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5> Update</h5>
            </div>
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="album_name">
                        Album Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={imageGallery?.album_name}
                        id="album_name"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Cover Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          id="fileInput"
                          onChange={(e) => {
                            setCover(e.target.files[0]);
                            setBtnActive(true);
                          }}
                          accept="image/*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div> */}


<div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                         Image
                      </label>
                      <div className="col-sm-9">
                        {cover ? (
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                width: "300px",
                                display: "block",
                                margin: "15px auto",
                              }}
                              src={cover}
                              alt=""
                            />
                            <span
                              style={{
                                backgroundColor: "red",
                                border: "none",
                                borderRadius: "7px",
                                position: "absolute",
                                right: "0",
                                top: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => setCover(null)}
                            >
                              <i
                                style={{
                                  color: "white",
                                  padding: "7px 7px",
                                  fontSize: "15px",
                                }}
                                className="fa fa-times"
                              ></i>
                            </span>
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            type="file"
                            id="fileInput"
                            onChange={(e) => {
                                setCover(e.target.files[0]);
                                setBtnActive(true);
                              }}
                            accept="image/*"
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>



                {/* <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Album Images
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          onChange={(e) => {
                            setImages(e.target.files);
                            setBtnActive(true);
                          }}
                          required
                          multiple
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateImageGallery;
