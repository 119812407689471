import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddCountry from "./AddCountry/AddCountry";
import Country from "./Country/Country";

const Countries = () => {
  const [countries, setCountries] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await axios.get("/countries");
        console.log(res?.data?.body?.data);
        setCountries(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getCountries();
  }, [isUpdate]);

  return (
    <div>
      <h2>Countries</h2>
      <div className="mb-3">
        <AddCountry isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {countries.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Flag</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {countries?.map((country, index) => (
              <Country
                key={country?.id}
                country={country}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Countries;
