import { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios/axios";

const UpdateVideoGallery = ({ videoGallery, index, isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [btnActive, setBtnActive] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const handleUpdate = async (id) => {
      const form = e.target;
      const name = form.name.value;
      const video_url = form.video_url.value;

      const postData = {
        name,
        video_url,
      };

      console.log(postData);

      try {
        await axios.put(`/video-gallery/${id}`, postData);
        Swal.fire({
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsUpdate(!isUpdate);
        toggle();
      } catch (error) {
        alert("Error submitting data.");
      }
    };

    handleUpdate(videoGallery.id);
  };

  return (
    <>
      <button
        style={{
          backgroundColor: "#515ef1",
          border: "none",
          borderRadius: "5px",
        }}
        onClick={toggle}
      >
        <i
          style={{
            color: "white",
            padding: "7px 3px",
            fontSize: "15px",
          }}
          className="fa fa-pencil"
        ></i>
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5>Video Gallery Update</h5>
            </div>
            <form
              className="form theme-form"
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        defaultValue={videoGallery?.name}
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="video_url">
                        Video URL
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="video_url"
                        defaultValue={videoGallery?.video_url}
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateVideoGallery;
