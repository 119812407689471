import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddImageGallery from "./AddImageGallery/AddImageGallery";
import ImageGallery from "./ImageGallery/ImageGallery";

const ImageGalleries = () => {
  const [imageGalleries, setImageGalleries] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getImageGalleries = async () => {
      try {
        const res = await axios.get("/image-gallery");
        console.log(res?.data?.body?.data);
        setImageGalleries(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getImageGalleries();
  }, [isUpdate]);

  return (
    <div>
      <h2>Image Galleries</h2>
      <div className="mb-3">
        <AddImageGallery isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {imageGalleries.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {imageGalleries?.map((imageGallery, index) => (
              <ImageGallery
                key={imageGallery?.id}
                imageGallery={imageGallery}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default ImageGalleries;
