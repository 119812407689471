import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddTeam from "./AddTeam/AddTeam";
import Team from "./Team/Team";


const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const res = await axios.get("/teams");
        console.log(res);
        setTeams(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchTeams();
  }, [isUpdate]);

  console.log("teams", teams);
  return (
    <div>
      <h2>Teams</h2>
      <div className="mb-3">
        <AddTeam isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {teams.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Designation</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {teams?.map((team, index) => (
              <Team
                key={team?.id}
                team={team}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Teams;
