import {
  Activity,
  Book,
  Calendar,
  Camera,
  Database,
  Globe,
  Home,
  Settings,
  User,
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Home",
    icon: Home,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        title: "Sliders",
        type: "link",
        path: "/sliders",
      },
      {
        title: "Home About Us",
        type: "link",
        path: "/home-about-us",
      },
      {
        title: "Partners",
        type: "link",
        path: "/partners",
      },
      {
        title: "Countries",
        type: "link",
        path: "/countries",
      },
      {
        title: "Newsletters",
        type: "link",
        path: "/newsletters",
      },
    ],
  },
  {
    title: "About",
    icon: Globe,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        title: "Who We Are",
        type: "link",
        path: "/who-we-are",
      },
      {
        title: "Vision And Mission",
        type: "link",
        path: "/vision-and-mission",
      },
      {
        title: "Values",
        type: "link",
        path: "/values",
      },
    ],
  },
  {
    title: "Services And Activities",
    icon: Activity,
    type: "link",
    path: "/services_and_activities",
    active: false,
  },
  {
    title: "Sectors",
    icon: Database,
    type: "link",
    path: "/sectors",
    active: false,
  },
  {
    title: "Fair Calender",
    icon: Calendar,
    type: "link",
    path: "/fair-calender",
    active: false,
  },
  {
    title: "Media",
    icon: Camera,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        title: "Image Gallery",
        type: "link",
        path: "/image-gallery",
      },
      {
        title: "Video Gallery",
        type: "link",
        path: "/video-gallery",
      },
    ],
  },
  {
    title: "Circulars",
    icon: Book,
    type: "link",
    path: "/circulars",
    active: false,
  },
  {
    title: "Teams",
    icon: User,
    type: "link",
    path: "/teams",
    active: false,
  },
  {
    title: "Settings",
    icon: Settings,
    type: "link",
    path: "/settings",
    active: false,
  },
];
