import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddSector from "./AddSector/AddSector";
import Sector from "./Sector/Sector";

const Sectors = () => {
  const [sectors, setSectors] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getSectors = async () => {
      try {
        const res = await axios.get("/sectors");
        console.log(res?.data?.body?.data);
        setSectors(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getSectors();
  }, [isUpdate]);

  return (
    <div>
      <h2>Sectors</h2>
      <div className="mb-3">
        <AddSector isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {sectors.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {sectors?.map((sector, index) => (
              <Sector
                key={sector?.id}
                sector={sector}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Sectors;
