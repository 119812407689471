import React from "react";

// sample page
import Circulars from "../components/Circulars/Circulars";
import Countries from "../components/Countries/Countries";
import FairCalenders from "../components/FairCalenders/FairCalenders";
import HomeAboutUs from "../components/HomeAboutUs/HomeAboutUs";
import ImageGalleries from "../components/ImageGalleries/ImageGalleries";
import Newsletters from "../components/Newsletters/Newsletters";
import Partners from "../components/Partners/Partners";
import Sectors from "../components/Sectors/Sectors";
import ServicesAndActivities from "../components/ServicesAndActivities/ServicesAndActivities";
import Settings from "../components/Settings/Settings";
import Sliders from "../components/Sliders/Sliders";
import Values from "../components/Values/Values";
import VideoGalleries from "../components/VideoGalleries/VideoGalleries";
import VisionAndMission from "../components/VisionAndMission/VisionAndMission";
import WhoWeAre from "../components/WhoWeAre/WhoWeAre";
import Teams from "../components/Team/Teams";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/sliders`, Component: <Sliders /> },
  {
    path: `${process.env.PUBLIC_URL}/home-about-us`,
    Component: <HomeAboutUs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/partners`,
    Component: <Partners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/circulars`,
    Component: <Circulars />,
  },
  {
    path: `${process.env.PUBLIC_URL}/countries`,
    Component: <Countries />,
  },
  {
    path: `${process.env.PUBLIC_URL}/newsletters`,
    Component: <Newsletters />,
  },
  {
    path: `${process.env.PUBLIC_URL}/who-we-are`,
    Component: <WhoWeAre />,
  },
  {
    path: `${process.env.PUBLIC_URL}/vision-and-mission`,
    Component: <VisionAndMission />,
  },
  {
    path: `${process.env.PUBLIC_URL}/values`,
    Component: <Values />,
  },
  {
    path: `${process.env.PUBLIC_URL}/services_and_activities`,
    Component: <ServicesAndActivities />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sectors`,
    Component: <Sectors />,
  },
  {
    path: `${process.env.PUBLIC_URL}/teams`,
    Component: <Teams />,
  },
  {
    path: `${process.env.PUBLIC_URL}/fair-calender`,
    Component: <FairCalenders />,
  },
  {
    path: `${process.env.PUBLIC_URL}/image-gallery`,
    Component: <ImageGalleries />,
  },
  {
    path: `${process.env.PUBLIC_URL}/video-gallery`,
    Component: <VideoGalleries />,
  },
  { path: `${process.env.PUBLIC_URL}/settings`, Component: <Settings /> },
];
