import { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios/axios";
import imageUploader from "../../../utilities/imageUploader";

const UpdateTeam = ({ team, index, isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(team.image_url);
  const [btnActive, setBtnActive] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const handleUpdate = async (id) => {
      const form = e.target;
      const name = form.name.value;
      const email = form.email.value;
      const designation = form.designation.value;
      const image_url = imgUrl ? imgUrl : await imageUploader(image);

      const postData = {
        name,
        email,
        designation,
        image_url,
      };

      try {
        await axios.put(`/teams/${id}`, postData);
        Swal.fire({
          icon: "success",
          name: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsUpdate(!isUpdate);
        toggle();
      } catch (error) {
        alert("Error submitting data.");
      }
    };

    handleUpdate(team.id);
  };

  return (
    <>
      <button
        style={{
          backgroundColor: "#515ef1",
          border: "none",
          borderRadius: "5px",
        }}
        onClick={toggle}
      >
        <i
          style={{
            color: "white",
            padding: "7px 3px",
            fontSize: "15px",
          }}
          className="fa fa-pencil"
        ></i>
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5>Team Update</h5>
            </div>
            <form
              className="form theme-form"
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        defaultValue={team?.name}
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        defaultValue={team?.email}
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="designation">
                        Designation
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="designation"
                        defaultValue={team?.designation}
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload Image
                      </label>
                      <div className="col-sm-9">
                        {imgUrl ? (
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                width: "300px",
                                display: "block",
                                margin: "15px auto",
                              }}
                              src={imgUrl}
                              alt=""
                            />
                            <span
                              style={{
                                backgroundColor: "red",
                                border: "none",
                                borderRadius: "7px",
                                position: "absolute",
                                right: "0",
                                top: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => setImgUrl(null)}
                            >
                              <i
                                style={{
                                  color: "white",
                                  padding: "7px 7px",
                                  fontSize: "15px",
                                }}
                                className="fa fa-times"
                              ></i>
                            </span>
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            type="file"
                            id="fileInput"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              setBtnActive(true);
                            }}
                            accept="image/*"
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateTeam;
