import axios from "axios";

const CLOUDINARY_PRESET = "ml_default";
const CLOUDINARY_CLOUDENAME = "dzmbym5t2";

const imageUploader = async (image) => {
  if (!image) {
    console.log("Please select an image first.");
    return "";
  }

  try {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", CLOUDINARY_PRESET);

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDENAME}/image/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Make sure to add this header
        },
      }
    );
    const imageUrl = response.data.secure_url;
    console.log(imageUrl);
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    return "Error uploading image. Please try again later.";
  }
};

export default imageUploader;
