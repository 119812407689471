import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../axios/axios";
import AddFairCalender from "./AddFairCalender/AddFairCalender";
import FairCalender from "./FairCalender/FairCalender";

const FairCalenders = () => {
  const [fairCalenders, setFairCalenders] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getFairCalenders = async () => {
      try {
        const res = await axios.get("/fair-calender");
        console.log(res?.data?.body?.data);
        setFairCalenders(res?.data?.body?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getFairCalenders();
  }, [isUpdate]);


  return (
    <div>
      <h2>Fair Calender</h2>
      <div className="mb-3">
        <AddFairCalender isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {fairCalenders.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Country Name</th>
              <th scope="col">Description</th>
              <th scope="col">Location</th>
              <th scope="col">Fair Name</th>
              <th scope="col">Duration</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {fairCalenders?.map((fairCalender, index) => (
              <FairCalender
                key={fairCalender?.id}
                fairCalender={fairCalender}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default FairCalenders;
