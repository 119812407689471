import React from "react";
import Swal from "sweetalert2";
import axios from "../../../axios/axios";
import UpdateFairCalender from "./../UpdateFairCalender/UpdateFairCalender";

const FairCalender = ({ fairCalender, index, isUpdate, setIsUpdate }) => {
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/fair-calender/${id}`);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          setIsUpdate(!isUpdate);
        } catch (error) {
          Swal.fire("Error!");
        }
      }
    });
  };

  return (
    <tr>
      <th>{index + 1}</th>
      <th>{fairCalender?.country_name}</th>
      <td>{fairCalender?.description}</td>
      <td>{fairCalender?.location}</td>
      <td>{fairCalender?.fair_name}</td>
      <td>{fairCalender?.duration_date}</td>
      <td style={{ minWidth: "100px" }}>
        <UpdateFairCalender
          fairCalender={fairCalender}
          index={index}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
        />
        <button
          style={{
            backgroundColor: "red",
            border: "none",
            borderRadius: "5px",
            marginLeft: "5px",
          }}
          onClick={() => handleDelete(fairCalender?.id)}
        >
          <i
            style={{
              color: "white",
              padding: "7px 3px",
              fontSize: "15px",
            }}
            className="fa fa-trash-o"
          ></i>
        </button>
      </td>
    </tr>
  );
};

export default FairCalender;
