import React, { Fragment, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios/axios";

const AddFairCalender = ({ isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [btnActive, setBtnActive] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const country_name = form.country_name.value;
    const description = form.description.value;
    const location = form.location.value;
    const fair_name = form.fair_name.value;
    const start_date = form.start_date.value;
    const end_date = form.end_date.value;

    const postData = {
      country_name,
      description,
      location,
      fair_name,
      start_date,
      end_date,
    };

    try {
      await axios.post("/fair-calender", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <Fragment>
      <Button color="primary" onClick={toggle}>
        Add New Fair Calender
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5>Fair Calender Uploader</h5>
            </div>
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="country_name">
                        Country Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="country_name"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="description">
                        Description
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="description"
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="location">
                        Location
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="location"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="fair_name">
                        Fair Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="fair_name"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="start_date">
                        Start Date
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="start_date"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="end_date">
                        End Date
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="end_date"
                        onChange={() => setBtnActive(true)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddFairCalender;
